<template>
    <div class="flex w-full flex-col items-center justify-center pt-16">
        <!-- Celebrating -->
        <div
            class="my-10 flex w-auto flex-row items-center gap-2 self-center rounded-full bg-[#F5FFD5] px-5 py-2.5 text-xs text-neutral-950 md:text-base"
        >
            <div class="flex flex-00auto items-center justify-center">
                <IconConfetti class="size-5" />
            </div>
            <div class="font-550">Celebrating 50,000+ hotels available!</div>
        </div>
        <div
            class="relative mb-12 max-w-screen-xl flex-col items-center justify-center"
        >
            <!-- Desktop Header -->
            <h1
                class="hidden text-center font-850 text-4xl leading-[80px] tracking-[-0.04em] md:block md:text-[90px] md:leading-[80px]"
            >
                Your Personal AI Travel Concierge
            </h1>
            <!-- Mobile Header -->
            <h1
                class="block px-6 text-center font-850 text-4.5xl tracking-[-0.04em] md:hidden"
            >
                Your Personal AI Travel Concierge
            </h1>
            <div
                class="mb-6 mt-2 flex justify-center text-center text-sm text-neutral-950/90 md:text-[20px]"
            >
                <!-- Mobile Subheader -->
                <h2
                    class="mx-2 block max-w-[270px] font-400 text-sm leading-[20.3px] md:mx-0 md:hidden"
                >
                    Get a travel concierge on WhatsApp to help you find backdoor
                    discounts and book the best rates for your upcoming trip.
                </h2>
                <!-- Desktop Subheader -->
                <h2 class="hidden max-w-[550px] pb-4 pt-6 leading-7 md:block">
                    Get a travel concierge on WhatsApp to help you find backdoor
                    discounts and book the best rates for your upcoming trip.
                </h2>
            </div>
            <BizRequestInviteWithArch @show-info-modal="showInfoModal = true" />
        </div>
        <BizCardsRow
            v-for="(content, i) in cardsRowContent"
            :key="content.key"
            :body="content.body"
            :title="content.title"
            :image="content.image"
            :inverted-row="content.key === '2'"
            :index="i"
        />
        <div
            class="mb-12 mt-20 w-[70vw] border-t-2 border-dashed border-neutral-600/40"
        />
        <!-- Backed By -->
        <div class="mx-0 mb-16 md:mx-4">
            <div class="text-center text-sm">Ascend is backed by:</div>
            <div
                class="mx-4 mt-4 grid max-w-[700px] grid-cols-3 justify-around gap-1 md:mx-0 md:grid-cols-3 md:gap-12"
            >
                <div class="flex justify-center md:justify-end md:pr-4">
                    <NuxtImg
                        src="/images/partner-logos/dst-logo.png"
                        class="mt-1 h-5 md:mt-0 md:h-8"
                    />
                </div>

                <div
                    class="-ml-4 flex flex-row items-center gap-1 sm:min-w-[120px] md:ml-0"
                >
                    <div
                        class="size-4 min-w-4 bg-orange-500 text-center text-xs text-neutral-50 md:size-6 md:text-base"
                    >
                        Y
                    </div>
                    <span class="text-orange-300">Combinator</span>
                </div>
                <NuxtImg
                    src="/images/partner-logos/accomplice-logo.png"
                    class="mt-1 h-6 md:mt-0 md:h-8"
                />
            </div>
        </div>
        <BizTestimonialsHeader />
        <BizRequestInviteWithArch @show-info-modal="showInfoModal = true" />
        <!-- Testimonials -->
        <HomePageTestimonials
            class="mt-6 max-w-[892px] px-8 md:mt-10 md:px-0"
            :show-header="false"
        />
        <div
            class="my-16 flex w-full flex-col items-center justify-center gap-12 bg-neutral-50 pb-28 pt-16"
        >
            <div class="text-center font-500 text-3xl lg:text-5xl">
                Travel to places like
            </div>
            <div class="flex flex-col justify-center gap-6 md:flex-row">
                <BizLocationCard
                    location="Paris, France"
                    sub-location="Eiffel Tower"
                    image="location-cards/eiffel-tower-location.jpg"
                />
                <BizLocationCard
                    location="Rome, Italy"
                    sub-location="The Coliseum"
                    image="location-cards/coliseum-location.jpg"
                    density="x1 x2"
                />
                <BizLocationCard
                    location="London, UK"
                    sub-location="Big Ben"
                    image="location-cards/big-ben-location.jpg"
                />
            </div>
            <div
                class="mx-8 max-w-[630px] text-center font-300 text-xs text-neutral-900 md:text-base"
            >
                We help you discover and book the perfect stay at a rate you'll
                love. Choose a destination to view deals available:
            </div>
            <!-- Invite Button -->
            <div class="flex w-full flex-col items-center justify-center">
                <div
                    class="flex w-fit cursor-pointer flex-row items-center gap-2 rounded-full bg-neutral-1000 px-8 py-4 font-400 text-[18px] text-neutral-50 outline-neutral-1000 hover:bg-neutral-700 active:bg-neutral-800"
                    @click="showInfoModal = true"
                >
                    <IconTelegram class="size-4" /><span> Request Invite</span>
                </div>
            </div>
        </div>
        <Modal
            v-model="showInfoModal"
            :panel-class="'!w-fit !max-w-[526px] !px-16  !rounded-3xl relative'"
            :show-top-right-close-button="true"
        >
            <BizContactForm />
        </Modal>
    </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'

// Here is our template ref typed as HTMLElement or null
const showInfoModal = ref(false)

useHead({
    titleTemplate: () => {
        return 'Ascend Travel'
    },
})

const cardsRowContent = computed(() => {
    return [
        {
            key: '1',
            title: 'Our travel concierge will WhatsApp you',
            image: '/images/wssp-cards/wspp-card-1.png',
            body: `We’ll start by texting you to learn your travel preferences and understand what you value most. Once we’ve got a feel for your needs, we’ll send you curated options that are a perfect match.`,
        },
        {
            key: '2',
            title: 'Share your upcoming trip details and your preferences',
            image: '/images/wssp-cards/wspp-card-2.png',
            body: `Let us know your destination, dates, and travel style. From flight classes to hotel vibes, we learn your preferences and plan every trip to match what you like best.`,
        },
        {
            key: '3',
            title: `We'll do the heavy lifting to get you the best deals`,
            image: '/images/wssp-cards/wspp-card-3.png',
            body: `We negotiate the best deals, so you don’t have to. We get you savings on flights, hotels, and more, while you focus on your business. For free.`,
        },
    ]
})
</script>
